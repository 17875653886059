import Particles from "react-tsparticles"
import particlesOptions from "config/pchain/particles/particles.json"
import { ISourceOptions } from "tsparticles";
import useTheme from 'hooks/useTheme'
import { configLightColors, configDarkColors, particlesLightColors, particlesDarkColors, configBaseColors} from 'config/pchain/pchain-config'

export default function PChainParticles() {
    const { theme, isDark, toggleTheme } = useTheme()

    // es necesario hacer una copia cada vez para que fuerce el repintado de las particles
    let particlesOpts=JSON.parse(JSON.stringify(particlesOptions));
    if(isDark){
        particlesOpts.background.color.value= configDarkColors.background
        if(particlesOpts?.particles?.color){
            particlesOpts.particles.color.value= particlesDarkColors
        }
        if(particlesOpts?.emitters){
            particlesOpts.emitters[0].particles.color.value=particlesDarkColors
        }
    }else{
        particlesOpts.background.color.value=  configLightColors.background
        if(particlesOpts?.particles?.color){
            particlesOpts.particles.color.value= particlesLightColors
        }
        if(particlesOpts?.emitters){
            particlesOpts.emitters[0].particles.color.value=particlesLightColors
        }
    }

    return(
        <Particles params={particlesOpts as ISourceOptions} />
    );    
}
